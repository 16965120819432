<template lang="html">
  <section class="home">
    <div class="container container-faculties">
      <router-link :to="{
        name: 'faculty',
        params: {
          faculty: key,
        },
      }" :title="`Ver orlas de ${faculty.name}`" v-for="(faculty, key) in store.faculties" :key='key'>
        <verticalBand :colors="faculty.colors"></verticalBand>
        <span>{{ faculty.name }}</span>
      </router-link>
    </div>
  </section>
</template>
<script lang="js">
import { useFacultiesStore } from '@/store/faculties'
import verticalBand from '../components/home/verticalBand.vue';

export default {
  name: 'home',
  props: {},
  components: { verticalBand },
  setup() {
    const store = useFacultiesStore();
    store.fetchFaculties();
    return {
      store
    }
  },
}
</script>