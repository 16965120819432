<template lang="html">
  <section class="vertical-band">
    <svg v-for="(color, key) in this.colors" :key='key' viewBox="0 0 31 101" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_235_1420)">
        <path
          d="M3.75323 0H27.3506V93.4013C27.3506 94.2184 26.4237 94.6906 25.7627 94.2101L17.1912 87.9797C16.5143 87.4876 15.6026 87.469 14.9062 87.9331L5.30773 94.3286C4.64316 94.7714 3.75323 94.295 3.75323 93.4965V0Z"
          :fill="color" />
      </g>
      <defs>
        <filter id="filter0_d_235_1420" x="0.753235" y="0" width="29.5974" height="100.498" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_235_1420" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_235_1420" result="shape" />
        </filter>
      </defs>
    </svg>
  </section>
</template>

<script lang="js">

export default {
  name: 'verticalBand',
  props: ['colors'],
  setup() {
    return {
    }
  },
}
</script>